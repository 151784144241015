import React from 'react'
import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundImageCSS = css`
  background-size: ${props =>
    props.styles && props.styles.backgroundSize ? props.styles.backgroundSize : 'cover'};
  background-position: ${props =>
    props.styles && props.styles.backgroundPosition
      ? props.styles.backgroundPosition
      : 'center'};
  background-repeat: ${props =>
    props.styles && props.styles.backgroundRepeat ? props.styles.backgroundRepeat : 'no-repeat'};;
`

const GatsbyBackgroundImage = styled(BackgroundImage)`
  ${BackgroundImageCSS};

  ${props => props.blackFade && css`
    background: linear-gradient(
      180deg,
      rgba(3, 113, 227, 0) 20%,
      rgba(0,0,0,1) 80%
    );
    opacity: 1 !important;
  `}
`

const Media = ({ blackFade, className, src, style, children, aboveFold }) => {
  const regular = !src.localFile || (src.url && src.url.localFile && src.url.localFile.publicURL) || (src.localFile && src.localFile.publicURL)

  if(regular) {
    let url = ''

    if(typeof src === 'string') {
      url = src
    } else if(src.url && src.url.localFile && src.url.localFile.publicURL) {
      url = src.url.localFile.publicURL
    } else if(src.localFile && src.localFile.publicURL) {
      url = src.localFile.publicURL
    } else {
      url = src.url
    }

    return (
      <GatsbyBackgroundImage
        blackFade={blackFade}
        className={className}
        fluid={{
          src: url
        }}
        styles={style}
        fadeIn={!aboveFold}
      >
        {children}
      </GatsbyBackgroundImage>
    )
  }
    
  return (
    <GatsbyBackgroundImage 
      blackFade={blackFade}
      className={className} 
      fluid={src.localFile.childImageSharp.fluid} 
      styles={style}
      fadeIn={!aboveFold}
    >
      {children}
    </GatsbyBackgroundImage>
  )
}

export default Media
