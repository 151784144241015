/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background: ${props => props.type === 'outline' ? props.theme.color.face.light : props.theme.color.face.main};
  border: 2px solid ${props => props.theme.color.face.main};
  display: inline-block;
  border-radius: 8px;
  transition: all 300ms ease-in;
  height: fit-content;
  cursor: pointer;

  &:hover {
    background: ${props => props.type === 'outline' ? props.theme.color.face.main : props.theme.color.face.light};
    
    & > a, & > button {
      color: ${props => props.type === 'outline' ? props.theme.color.face.light : props.theme.color.face.main};
    }
  }

  & > a, & > button {
    color: ${(props) => props.type === 'outline' ? props.theme.color.face.main : props.theme.color.face.light};
    padding: 17px 56px;
    display: block;
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: none;
    line-height: 30px;
    transition: all 300ms ease-in;

    &:hover {
      text-decoration: none;
    }

    @media screen and (max-width: 992px) {
      padding: 10px 24px;
    }
  }
`

const ButtonDefault = ({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  ...props
}) => (
  <StyledButton className={className} {...props}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
