/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { FaPhoneAlt, FaEnvelope, FaLinkedinIn } from 'react-icons/fa'
// Images
import Logo from 'img/VMT-Productions.inline.svg'
import LogoImg from 'img/logo.png'
import SelectedIcon1 from 'img/menu-icon1.svg'
import SelectedIcon2 from 'img/menu-icon2.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const StyledHeader = styled.nav`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  margin-top: auto;
  margin-bottom: auto;
  & > img {
    height: 40px;
    @media screen and (max-width: 992px) {
      height: 30px;
    }
  }
`

const StyledLogo = styled(Logo)`
  height: 40px;
    @media screen and (max-width: 992px) {
      height: 30px;
    }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const Header = ({ activePage }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
        acf {
          menu {
            link {
              title
              url
              target
            }
            submenu {
              link {
                target
                title
                url
              }
            }
          }
          extra {
            phonenumber
            email
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <TopMenu>
        <div className="container d-flex justify-content-end">
          <FaPhoneAlt color="black" className="my-auto" size={16} />
          <a className="ml-2" href={`tel:${fields.acf.extra.phonenumber}`}>{fields.acf.extra.phonenumber}</a>
          <FaEnvelope className="ml-5 my-auto" color="black" size={16} />
          <a className="ml-2" href={`mailto:${fields.acf.extra.email}`}>{fields.acf.extra.email}</a>
        </div>
      </TopMenu>
      <MenuMobile fields={fields} activePage={activePage} />
      <MenuDesktop fields={fields} activePage={activePage} />
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  color: ${(props) => props.theme.color.text.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  max-height: ${(props) => (props.isVisible ? '100vh' : 0)};
  overflow: hidden;
  transition: all 250ms ease-in;
  & > ul {
    padding-left: 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 50px auto 50px auto;

    & li {
      padding: 0;

      & a {
        font-size: ${(props) => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.main};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuMobile = ({ fields, activePage }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <div className="d-flex justify-content-between pl-3 py-1">
        <Brand to="/">
          <StyledLogo />
        </Brand>
        <NavButton role="button" onClick={() => setIsOpen(!isOpen)}>
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonStripe />
        </NavButton>
      </div>
      <MenuWrapper isVisible={isOpen}>
        <NavButtonClose role="button" onClick={() => setIsOpen(!isOpen)}>
          <NavButtonCloseCross />
        </NavButtonClose>
        <ul>
          {fields.acf.menu.map(m => (
            <Item active={activePage === m.link.url} selectedType={1}>
              <CustomLink to={m.link.url}>{m.link.title}</CustomLink>
              {m.submenu[0].link.title !== 'Empty' && (
                <div className="position-relative">
                  <DropDown>
                    {m.submenu.map(s => (
                      <DropDownItem to={s.link.url}>
                        {s.link.title}
                      </DropDownItem>
                    ))}
                  </DropDown>
                </div>
              )}
            </Item>
          ))}
        </ul>
      </MenuWrapper>
    </div>
  )
}

const StyledMenuDesktop = styled.div`
  background: white;
`

const TopMenu = styled.div`
  height: 40px;
  background: ${(props) => props.theme.color.face.secondary};
  color: ${(props) => props.theme.color.text.main};
  font-size: 14px;
  line-height: 40px;
`

const MenuDesktop = ({ fields, activePage }) => {
  return (
    <StyledMenuDesktop className="d-none d-lg-flex">
      <div className="container d-flex justify-content-between">
        <Brand to="/">
          <StyledLogo />
        </Brand>

        {fields.acf.menu.map(m => (
          <Item active={activePage === m.link.url} selectedType={1}>
            <CustomLink to={m.link.url}>{m.link.title}</CustomLink>
            {m.submenu[0].link.title !== 'Empty' && (
              <div className="position-relative">
                <DropDown>
                  {m.submenu.map(s => (
                    <DropDownItem to={s.link.url}>
                      {s.link.title}
                    </DropDownItem>
                  ))}
                </DropDown>
              </div>
            )}
          </Item>
        ))}

        <SocialIcon
          className="my-auto"
          href="https://www.linkedin.com/company/vmtbv/"
          target="_blank"
        >
          <FaLinkedinIn color="white" size={18} />
        </SocialIcon>
      </div>
    </StyledMenuDesktop>
  )
}

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  & > a {
    transition: all 250ms ease-in;
    font-family: ${(props) => props.theme.font.family.secondary};
    color: ${(props) =>
      props.active ? props.theme.color.face.main : props.theme.color.text.main};
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;

    &[aria-current] {
    }

    &:hover {
      color: ${(props) => props.theme.color.face.main};
      text-decoration: none;
    }
  }

  @media screen and (max-width: 991px) {
    align-items: center;
    height: 60px;
    background: none;
  }

  background: ${(props) =>
    props.active && props.selectedType === 1 ? 
      `url(${SelectedIcon1}) no-repeat bottom center`
    : props.active && props.selectedType === 2 ? 
      `url(${SelectedIcon2}) no-repeat bottom center`
    : 
      ''
  };
`

const DropDown = styled.div`
  margin-top: -3px;
  position: absolute;
  overflow: hidden;
  max-height: 0;
  background: white;
  border-radius: 0px;
  width: 250px;
  margin-left: -60px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
  transition: max-height 200ms ease-in;
  z-index: 99;
  ${Item}:hover & {
    max-height: 500px;
  }

  @media screen and (max-width: 991px) {
    margin-left: -100px;
  }
`

const DropDownItem = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.main};
  cursor: pointer;
  padding: 10px 20px;
  display: block;
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight: ${(props) => props.theme.font.weight.m};
  line-height: ${(props) => props.theme.font.size.l};
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    font-weight: ${(props) => props.theme.font.weight.l};
    text-decoration: none;
  }
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    margin-bottom: 10px;
  }
`

const SocialIcon = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: black;
  display: flex;
  justify-content: center;
  padding-top: 6px;
`

const Menu = ({ fields }) => (
  <>
    {fields.acf.menu.map(({ link: { url, title } }, index) => (
      <Item key={index}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
