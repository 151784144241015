/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Third Party
import styled from 'styled-components'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  color: ${(props) => props.theme.color.text.light};
`

const Footer = () => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 98 }) {
        acf {
          menu {
            link {
              title
              url
            }
          }
          services {
            link {
              title
              url
            }
          }
          contact {
            address
            zipcode_city
            phonenumber
            email
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container pt-5 pb-2">
        <div className="row">
          <div className="col-lg-4 mb-5">
            <Heading className="mb-4">MENU</Heading>
            <ItemContainer>
              {fields.acf.menu.map(({ link: { url, title } }, index) => (
                <Item to={url} key={index}>
                  {title}
                </Item>
              ))}
            </ItemContainer>
          </div>
          <div className="col-lg-4 mb-5">
            <Heading className="mb-4">DIENSTEN</Heading>
            <ItemContainer>
              {fields.acf.services.map(({ link: { url, title } }, index) => (
                <Item to={url} key={index}>
                  {title}
                </Item>
              ))}
            </ItemContainer>
          </div>
          <div className="col-lg-4 mb-5">
            <Heading className="mb-4">CONTACT</Heading>
            <div className="d-flex mb-3">
              <FaMapMarkerAlt color="white" size={24} className="mr-2" />
              <a href="https://goo.gl/maps/FFxbKwe5Nwzbmr8MA" target="_blank">
                <p className="mb-3"> {fields.acf.contact.address}</p>
                <p className="mb-0"> {fields.acf.contact.zipcode_city}</p>
              </a>
            </div>
            <div className="d-flex mb-3">
              <FaPhoneAlt color="white" size={24} className="mr-2" />
              <a href={`tel:${fields.acf.contact.phonenumber}`}>
                {fields.acf.contact.phonenumber}
              </a>
            </div>
            <div className="d-flex">
              <FaEnvelope color="white" size={24} className="mr-2" />
              <a href={`mailto:${fields.acf.contact.email}`}>
                {fields.acf.contact.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

const Heading = styled.h4`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.text.light};
`
const Item = styled(Link)`
  display: block;
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.text.light};
  margin: 16px 0;
  &:hover {
    color: ${(props) => props.theme.color.text.light};
  }
`
const ItemContainer = styled.div`
  border-left: 2px solid ${(props) => props.theme.color.face.main};
  padding: 4px 4px 4px 32px;
`
