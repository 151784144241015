/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Libraries
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Content = styled.div`
  /* padding-top: 60px; */
`

const CookieLink = styled(Link)`
  color: ${(props) => props.theme.color.text.light};
  text-decoration: underline;
`

const Layout = ({ children, activePage }) => {
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])
  return (
    <Theme>
      <Header isSticky={isSticky} activePage={activePage} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer />

      <CookieConsent
        location="bottom"
        style={{ backgroundColor: '#000000', color: '#FFFFFF' }}
        buttonText="Akkoord"
      >
        <div className="px-lg-5 font-size-sm">
          <div>
            We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
            mogelijk draait. Als u doorgaat met het gebruiken van de website,
            gaan we er vanuit dat u ermee instemt.{' '}
            <CookieLink to="/privacyverklaring">
              Lees hier de privacyverklaring
            </CookieLink>
          </div>
        </div>
      </CookieConsent>
    </Theme>
  )
}

export default Layout
