/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { renderToString } from 'react-dom/server'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import CustomLink from './CustomLink'

const Content = styled.div`
  & ul {
    
    & li {
      
    }
  }

  & a {
    
  }

  & img {

  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`

const convertCSS = style => {
  const result = {}
  
  if(!style) {
    return result
  }
  
  const attributes = style.split(';');

  attributes.forEach(attribute => {
    const entry = attribute.split(':')
    // eslint-disable-next-line prefer-destructuring
    result[entry[0]] = entry[1]
  })

  return result
}

const findAndReplace = (content, settings) => {
  return parse(content, {
    replace: (domNode) => {
      if (domNode.children && domNode.children.length > 0) {
        const html = renderToString(domToReact(domNode.children, {}))
        const htmlFindAndReplace = findAndReplace(html, settings)

        const attributes = domNode.attribs

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if(attributes.href) {
          if (
            attributes.href.indexOf('www.') !== -1 ||
            attributes.href.indexOf('http:') !== -1 ||
            attributes.href.indexOf('https:') !== -1
          ) {
            attributes.target = '_blank'
          } else if(attributes.href.indexOf('/wp-content/') !== -1 && attributes.href.indexOf(settings.sourceUrl) === -1) {
            attributes.href = `${settings.sourceUrl}${attributes.href}`
          }
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        const Comp = domNode.name

        return (
          <Comp
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            id={attributes.id}
            href={attributes.href}
            target={attributes.target}
          >
            {htmlFindAndReplace}
          </Comp>
        )
      }

      if (domNode.name === 'img') {
        const attributes = domNode.attribs

        if (domNode.attribs.src.indexOf('/wp-content/') !== -1 && domNode.attribs.src.indexOf(settings.sourceUrl) === -1) {
          attributes.src = `${settings.sourceUrl}${domNode.attribs.src}`
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        return (
          <img
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            width={attributes.width}
            height={attributes.height}
            alt={attributes.alt}
          />
        )
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          <CustomLink to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </CustomLink>
        )
      }
    },
  })
}

const ParseContent = ({ content, className }) => {
  const {
    site
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if(site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if(!content) {
    return ''
  }

  return (
    <Content className={className}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent